export const CarDetails = {
  make: '',
  model: '',
  year: '',
  exist: false
}

export const APIBaseUrl = 'https://api.canadawidecarparts.tech/api/v1'
export const APIDomain = 'https://api.canadawidecarparts.tech'
//  export const APIBaseUrl = 'http://localhost:8080/api/v1'
//export const APIDomain = 'http://localhost:8080'
